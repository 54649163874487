.setup-page-header {
  flex-shrink: 0;
  padding: 10px 10px;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.setup-page-header-operation {
  padding: 0;
}

.space-setup-page-header  {
  margin: 50px 0px;
}

.space-setup-page-header-operation  {
  margin: 14px 0px;
}

.tag-status-rb {
  width: 91px
}

.header-cab-1 {
  display: flex;
  justify-content: space-between;  
  width: 100%;  
}

.data-details-operations {
  font-size: 14px;
  width: 50vw;
}

.title-label-details-operations {
  color: #0E83D8;
  font-size: 12px;
  width: 50vw;
}

.opTable .ant-table-container,
.opTable .ant-table-body {
  min-height: 300px !important;  
  /* width: 300px !important;   */
  overflow: auto !important;   
}

.ant-table-body > table {
  table-layout: auto !important;
  /* width: 920px !important;  */
}
.ant-table-header > table {
  /* table-layout: auto !important; */
  /* width: 920px !important;  */
}


.opTable .ant-table,
.opTable .ant-table-thead > tr .ant-table-cell,
.opTable .ant-table-footer {
  background: #141414 !important;
}

.opTable .ant-table-footer {
  color: #141414 !important;
}

.ant-card-head {
  border-bottom: 0px;
}

.tag-status-details-rb {
  width: 145px !important;
}

.format-reinvest .ant-form-item-label > label,
.format-reinvest .ant-form-item-label .ant-form-item-required,
.format-reinvest .ant-form-item-label .ant-form-item-required :disabled
{
  color: #0E83D8 !important;    
}

.format-reinvest {
  width: 268px !important;
}

.header-format-reinvest {
  display: flex !important;  
  height: 70px;  
}

.header-format-reinvest .ant-row {
  display: block !important;
  margin-right: 10px !important;
}

.header-format-reinvest .ant-row .ant-col{
  overflow: visible;  
}

.header-format-reinvest .ant-row .ant-col .ant-form-item-required::before {
  content: '';
}

.header-format-reinvest .ant-row .ant-col .ant-form-item-required::after {
  content: '';
}

.button-simu {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: flex-end; 
  margin-bottom: 5px;
}

.header-mobile {
  width: 1060px !important;   
  overflow: visible !important;  
}

.card-header {
  width: 100% !important;   
}

@media only screen and (max-width: 600px){
  .card-header {
    width: 100% !important;   
    overflow: auto !important;  
  }
}

@media only screen and (max-width: 800px) and (orientation: landscape){
  .card-header {
    width: 100% !important;   
    overflow: auto !important;  
  }
}
