.cardPortfolio .ant-card-body {
  padding-bottom: 0;
  margin: 12px;
}

.filtersForm {
  margin: 0 -4px;
  /* padding-bottom: 24px; */
}

.filtersForm .ant-row.ant-form-item {
  margin: 0 4px;
}

.filtersSubmit {
  margin-left: 8px;
}

@media only screen and (max-width: 900px) {
  .filtersForm .ant-row.ant-form-item {
    margin-bottom: 8px;
  }

  .filtersSubmit {
    margin-left: 0px;
    margin-top: 8px;
  }
}