.opContent {
  opacity: 0;
  transition: all 0.3s ease;
}

.tableTitle {
  font-size: 20px;
  color: #419FF5;
  margin-top: 6px;
}

.opTable .ant-table-footer {
  color: #419FF5 !important;
}

.opTable .columnGroup {
  color: #419FF5 !important
}

.opTable--details .ant-table-tbody td:nth-child(4) {
  border-right: solid 1px #666 !important
}

.opTable--details .ant-table-tbody td:nth-child(8) {
  border-right: solid 1px #666 !important
}

.opTable--details .ant-table-tbody {
  font-size: 11px;
}

.opTable--details .ant-table-tbody td:nth-child(1),
.opTable--details .ant-table-tbody td:nth-child(2),
.opTable--details .ant-table-tbody td:nth-child(3),
.opTable--details .ant-table-tbody td:nth-child(4) {
  color: green;
}

.opTable--details .ant-table-tbody td:nth-child(5),
.opTable--details .ant-table-tbody td:nth-child(6),
.opTable--details .ant-table-tbody td:nth-child(7),
.opTable--details .ant-table-tbody td:nth-child(8) {
  color: red;
}

.infoTable {
  /* padding-bottom: 24px; */
  margin: 0 -24px;
  font-size: 16px;
}

.infoTable__item {
  margin: 0 24px;
  font-weight: 200;
}

.infoTable__item span {
  color: #419FF5;
  font-weight: 500;
  padding-right: 8px;
}

.opTable .ant-table-container,
.opTable .ant-table-body {
  /* min-height: 370px !important; */
  overflow: auto auto !important;  
}

.exportButtonMain {
  margin-bottom: 24px;
}

.exportButtonResume,
.exportButtonDetails {
  float: right;
}

.opTable .ant-table-container {
  border: 0;
}

.opTable .ant-table-footer {
  color: #141414 !important;
}

.opTable .ant-table table {
  padding-left: 10px;
}

.opTable .rowClass {
  background-color: #141414;  
}

.opTable .columnGroup {
  color: #fff !important;
  border-bottom: 1px solid #3a3a3a !important;
  text-align: start !important;
}

.buttonsMain {
  gap: 0 !important;
}

.buttonsMain .ant-space-item {
  margin-right: 3px !important;
}

.buttonsResume {
  gap: 0 !important;
}

.buttonsResume .ant-space-item {
  margin-right: 3px !important;
}

/* MOBILE-TABLET */

@media only screen and (max-width: 900px) {
  .exportButtonMain {
    position: absolute;
    top: -80px;
    right: 0;
  }

  .exportButtonResume,
  .exportButtonDetails {
    float: none;
    position: absolute;
    top: -74px;
    right: 0;
  }

  .opTable {
    margin-top: 16px;
    min-height:600px!important;
  }

  .opTable .ant-table-header,
  .opTable .ant-table-body {
    width: 1000px;
  }

  .infoTable {
    /* padding-bottom: 12px; */
    margin: -4px -12px;
    font-size: 14px;
  }
  
  .infoTable__item {
    margin: 4px 12px;
    font-weight: 200;
  }
  
  .infoTable__item span {
    color: #419FF5;
    font-weight: 500;
    font-size: 12px;
  }
}

.opTable .ant-table,
.opTable .ant-table-thead > tr .ant-table-cell,
.opTable .ant-table-footer {
  background: #141414 !important;
  padding: 8px;
}

.opTable .ant-table-container {
  border: 0;
}

.opTableMain .ant-table-tbody > tr .ant-table-cell:last-child {
  display: flex;
  justify-content: space-around;  
}

.opTableResume .ant-table-tbody > tr .ant-table-cell:last-child {
  display: flex;
  justify-content: center;    
}

.opTable .ant-table-tbody > tr .ant-table-cell {
  border-bottom: 0.2px solid #3a3a3a !important;  
  border-right: 0 !important;
}

.opTable .ant-table-footer {
  /* color: #141414 !important; */
}

.opTable .ant-table table {
  padding-left: 10px;
}

.opTable .columnGroup {
  color: #FFF !important;
  border-bottom: 1px solid #3a3a3a !important;
  text-align: start !important;
}

.opTable .ant-table,
.opTable .ant-table-thead > tr .ant-table-cell,
.opTable .ant-table-footer {
  background: #141414 !important;
  padding: 8px;
}

.detailCard .ant-card-body {
  padding-bottom: 0;
}