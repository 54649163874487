.settings-container {
  padding: 9px;
  margin: 9px;
}

.password-format .ant-form-item-label .ant-form-item-required,
.preference-format .ant-form-item-label .ant-form-item-required
{
  color: #0E83D8 !important;
}

.profile-format .ant-form-item-label .ant-form-item-required {
  color: #0E83D8 !important;
}

.preference-format {
  display: block !important;
}

.password-format {
  margin-bottom: 5px !important;
}

.profile-format {
  display: block !important;
  margin-bottom: 5px !important;
}

@media only screen and (max-width: 600px) {
  .profile-photo .ant-image-img {
    height: 50px !important;
    width: 50px !important;
  }

  .profile-photo .ant-image {
    display: flex;
    align-items: center;  
    height: 20px !important;  
    width: 40px !important;  
  }  

  .icone-preference, .icone-password, .icone-security{
    font-size: 25px !important;  
  }
}

.profile-photo {
  /* height: 120px; */
  width: 120px;
  border-radius: 50%;
  margin-right: 10px;
}

.profile-photo-modal {
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin-right: 20px;
}

.icone-preference {
  font-size: 35px;
  padding-right: 10px;
}

.icone-password {
  font-size: 35px;
  padding-right: 10px;
}

.icone-security {
  font-size: 35px;
  padding-right: 10px;
}