.exchanges-container {
  padding: 0px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 9px;  
}

.exchanges-container-cards {
  height: 860px;
}

.spc-header-exchanges {
  margin: 50px 0px;
}

.exchange-align .ant-col {
  margin-left: 0 !important;  
}

.exchange-align .ant-form-item-label .ant-form-item-required{
  color: #0E83D8 !important;
}

.image-symbol {
  width: 30px;
  height: 30px;
  margin-right: 10px;  
}

.opTable-coins .ant-table-row .ant-table-cell {
  padding: 7px !important;
  font-size: 14px !important;    
}

.rowClassExchange .ant-table-cell,
.opTable-coins  .ant-table-thead .ant-table-cell{
  padding-right: 10px !important;
}

.list-exchanges {
  display: flex !important;
  flex-direction: column !important;
}
