.stop-menu-bar {
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #303030;
  bottom: 0;
  right: 0;
  position: absolute;
  width: 100%;
}

.line {
  margin: 9px 0px;
}

/* .header-format-stop {
  display: flex !important;  
  height: 70px;  
} */

.header-format-stop .ant-row {
  flex-direction: column !important;
  align-items: flex-start !important;
}

.header-format-stop .ant-row .ant-col{
  overflow: visible;  
}

.header-format-stop .ant-row .ant-col .ant-form-item-required::before {
  content: '';
}

.header-format-stop .ant-row .ant-col .ant-form-item-required::after {
  content: '';
}
.format-stop .ant-form-item-label > label,
.format-stop .ant-form-item-label .ant-form-item-required,
.format-stop .ant-form-item-label .ant-form-item-required :disabled
{
  color: #0E83D8 !important;    
}