.patchNotes {
    margin-bottom: 64px;
}

.patchNotes .mainTitle {
    color: #999;
    font-size: 22px;
    margin: 24px 24px 0;
}

.patchNotes .ant-avatar {
    border-radius: 0 !important;
}

.patchNotes .ant-list-item-meta-title > div {
    color: #419FF5;
    font-size: 14px;
}

.patchNotes .ant-list-header {
    font-size: 16px;
    font-weight: bold;
    color: #999;
}

.patchNotes .ant-list-header,
.patchNotes .ant-list-footer {
    background-color: #191919;
}

.patchNotes .ant-list-footer {
    font-weight: bold;
}

.patchNotes .ant-list-split .ant-list-item {
border-bottom: 1px solid #666;
}

.patchNotes .ant-list-item:nth-child(even) {
    background-color: #191919;
}

.patchNotes .ant-list-item-meta {
    align-items: center;
}

.patchNotes .ant-list-item-meta-title {
    margin-bottom: 0;
}

.patchNotes .ant-list-item-meta-description {
    margin-bottom: 4px;
}

.patchNotes ._Corretiva .ant-list-item-meta-title > div,
.patchNotes ._Bug .ant-list-item-meta-title > div,
.patchNotes ._Fix .ant-list-item-meta-title > div {
    color: rgb(239, 24, 24);
}

.patchNotes ._Melhoria .ant-list-item-meta-title > div,
.patchNotes ._Improvement .ant-list-item-meta-title > div {
    color: orange;
}

.patchNotes ._Funcionalidade .ant-list-item-meta-title > div,
.patchNotes ._Functionality .ant-list-item-meta-title > div {
    color: rgb(4, 175, 4);
}

.patchNotes ._Tela .ant-list-item-meta-title > div,
.patchNotes ._Screen .ant-list-item-meta-title > div {
    color: rgb(182, 210, 22);
}

.patchNotes ._NovoSistema .ant-list-item-meta-title > div,
.patchNotes ._NewSystem .ant-list-item-meta-title > div {
    color: #419FF5;
}
  
.patchNotes .ant-tabs-content-holder {
    margin-right: 24px;
}

@media only screen and (max-width: 900px) {
    .patchNotes .mainTitle {
        font-size: 18px;
        margin-bottom: 0;
    }

    .patchNotes .ant-space.ant-space-horizontal.ant-space-align-center {
        margin-bottom: 14px !important;
    }

    .patchNotes .ant-list-item-meta-title > div {
        font-size: 12px;
    }

    .patchNotes .ant-list-header {
        font-size: 16px;
    }

    .patchNotes .ant-tabs-content-holder {
        margin-left: 24px;
    }

    .patchNotes .ant-tabs-nav {
        margin: 0 24px 24px;
    }
  }