.operations-container {
  padding: 9px;
  max-height: 900px !important;
}

.usrTable .ant-table-container,
.usrTable .ant-table-body {
  min-height: 260px !important;
  overflow: auto auto !important;    
}

.tab-container-operation {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 5px;
  padding-bottom: 8px;
  background: #141414;  
  max-height: 900px !important;
}

.tab-container-main-operation {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 0px;
  padding-bottom: 3px;
  background: #141414;  
  max-height: 900px !important;
}

.input-operation {
  color:  #929AA5;
}

.line-operation {
  height: 9px;
  background-color:  #000000 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.line-operation-select {
  height: 9px;
  background-color:  #000000 !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.select-container-operation {
  background-color:  #000000 !important;
  width: 100% !important;  
}

.rowClass .ant-table-cell,
.sell .ant-table-thead .ant-table-cell{
  padding-right: 10px !important;
}

.sell-input {
  outline: 1px solid rgb(67, 67, 67);  
  transition: ease 0.2s all;
}

.sell-input:hover {
  outline-color: #165996;  
}

.ant-input-number:focus, .ant-input-number-focused {
  border-color: rgb(67, 67, 67);  
  box-shadow: none;
}

.input-amount > div > input {
  text-align: right !important;
  padding: 0 24px 0 0 !important;
}