.menu-bar {
  flex-shrink: 0;
  padding: 10px 10px;
  /* border-top: 1px solid #303030; */
  bottom: 0;
  right: 0;
  position: absolute;
  width: 100%;
}

.profitPg-simulateResults .ant-table-wrapper {
  margin-left: calc(100% / 6); /* align using col-6 settings */
}

.profitPg-simulateResults td {
  padding: 8px !important;
}

.profitPg-simulateResults tr > td:nth-child(1) {
  color: grey;
  font-size: 0.90rem !important;
}

.profitPg-simulateResults tr > td:nth-child(2) {
  color: #fff;
  font-size: 0.90rem !important;
  padding-right: 16px !important;
}

.profitPg-simulateResults .ant-table-body {
  overflow: hidden scroll !important;
}

.profitPg-simulateResults .ant-table-thead tr th {
  font-weight: normal !important;
}

.profitPg-simulateResults .ant-table-thead tr th:nth-child(2) {
  text-align: center !important;
}

.profitPg-simulateResultsTitle {
  padding-left: calc(100% / 6); /* align using col-6 settings */
  color: #3392eb;
  font-weight: normal;
  text-align: center;
  font-size: 0.90rem;
}

.header-steps-editing {
  display: flex !important;  
  height: 70px;
}

.header-steps-editing .ant-row {
  display: block !important;
}

.header-steps-editing .ant-row .ant-col{
  overflow: visible;  
}

.header-steps-editing .ant-row .ant-col .ant-form-item-required::before {
  content: '';
}

.header-steps-editing .ant-row .ant-col .ant-form-item-required::after {
  content: '';
}

.coin-format-editing .ant-form-item-label .ant-form-item-required,
.coin-format-editing .ant-form-item-label .ant-form-item-required :disabled
{
  color: #0E83D8 !important;    
}

.card-editing .ant-card-body{
  padding: 0 12px 0px 12px 
}

.coin-format-editing-field {
  width: 170px !important; 
}

.coin-format-editing-field-mobile {
  width: 100% !important; 
}
