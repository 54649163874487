.coin-format .ant-form-item-label .ant-form-item-required,
.steps-content .ant-form-item-label .ant-form-item-required,
.coin-format .ant-form-item-label > label
{
  color: #0E83D8 !important;
}

.coin-format .ant-form-item-label .ant-form-item-required::after,
.steps-content .ant-form-item-label .ant-form-item-required::after,
.coin-format .ant-form-item-label > label::after {
  content: '';
}

.coin-format .ant-form-item-label > label.ant-form-item-required::before {
  content: '';
  margin-right: 0;
}

.coin-format {
  display: block !important;
  width: 160px !important;
}

.coin-format .ant-form-item-label {
  text-align: start !important;  
  max-width: 100% !important;  
}

.site-card-border-less-wrapper {
  padding: 24px;
  border: solid 1px #444444;
}

.site-card-border-less-wrapper .ant-card {
  background: #252525 !important;
}

/* MOBILE-TABLE */

@media only screen and (max-width: 900px) {
  .site-card-border-less-wrapper {
    padding: 0;
    margin-bottom: 16px;
    border: none;
  }

  .site-card-border-less-wrapper .ant-card {
    margin: 8px 0;
  }

  .site-card-border-less-wrapper .ant-row {
    margin: -8px 0;
  }
}

.card-dashboard .ant-card-head{
  border: none !important;
}
/* 
.apexcharts-legend-text {
 color: #fff !important;
} */

svg.apexcharts-svg { 
  background: transparent !important; 
}

.accompanimentsDetail .ant-table,
.accompanimentsDetail .ant-table-thead > tr .ant-table-cell,
.accompanimentsDetail .ant-table-footer {
  background: #141414 !important;
}

.accompanimentsDetail .ant-table-footer {
  color: #419ff5 !important;
}

.accompanimentsDetail .rowClass {
  background-color: #141414;
}

.accompanimentsDetail .ant-table-thead > tr .ant-table-cell {
  color: #419ff5 !important;
}

.accompanimentsDetail .ant-table-container,
.accompanimentsDetail .ant-table-body {
  min-height: 200px !important;
  overflow: auto auto !important;
  background: #141414;
}

.symbolsUpdate {
  text-align: right;
}

/* MOBILE-TABLET */

@media only screen and (max-width: 900px) {
  .symbolsUpdate {
    text-align: left;
    margin-top: 16px;
  }

  .symbolsUpdate button {
    width: 100%;
  }

  .accompanimentsDetail {
    margin-top: 16px;
  }

  .accompanimentsDetail .ant-table-header,
  .accompanimentsDetail .ant-table-body {
    width: 900px;
  }
}

.opTableOverview .ant-table-container,
.opTableOverview .ant-table-body {
  min-height: 415px !important;
  overflow: auto auto !important;      
}


.opTableOverview .ant-table,
.opTableOverview .ant-table-thead > tr .ant-table-cell,
.opTableOverview .ant-table-footer {
  background: #141414 !important;
  padding: 8px;
}

.opTableOverview .ant-table-container {
  border: 0;
}

.opTableOverviewMain .ant-table-tbody > tr .ant-table-cell:last-child {
  display: flex;
  justify-content: space-around;  
}

.opTableOverviewResume .ant-table-tbody > tr .ant-table-cell:last-child {
  display: flex;
  justify-content: center;    
}

.opTableOverview .ant-table-tbody > tr .ant-table-cell {
  border-bottom: 0.2px solid #3a3a3a !important;  
  border-right: 0 !important;
}

.opTableOverview .ant-table table {
  padding-left: 10px;
}

.opTableOverview .columnGroup {
  color: #FFF !important;
  border-bottom: 1px solid #3a3a3a !important;
  text-align: start !important;
}

.opTableOverview .ant-table,
.opTableOverview .ant-table-thead > tr .ant-table-cell,
.opTableOverview .ant-table-footer {
  background: #141414 !important;
  padding: 8px !important;
}

.opTableOverview .ant-table-container {
  border: 0;
}

.opTableOverview .ant-table-footer {
  color: #141414 !important;
}

.opTableOverview .ant-table table {
  padding-left: 10px;
}

.opTableOverview .rowClass {
  background-color: #141414;  
}