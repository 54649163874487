.coin-format .ant-form-item-label {
  text-align: start !important;
  color: #0E83D8 !important;
  max-width: 100% !important;  
}

.coin-format .ant-form-item-label .ant-form-item-required,
.steps-content .ant-form-item-label .ant-form-item-required
{
  color: #0E83D8 !important;
}

.coin-format .ant-form-item-label,
.steps-content .ant-form-item-label
{
  color: #0E83D8 !important;
}

.coin-format .ant-form-item-label .ant-form-item-required::after,
.steps-content .ant-form-item-label .ant-form-item-required::after {
  content: '';
}

.coin-format .ant-form-item-label > label.ant-form-item-required::before {
  content: '';
  margin-right: 0;
}

.header-steps .ant-row {
  display: block !important;
  width: 10rem;
}

.header-steps .ant-row:last-child {
  display: block !important;
  margin-left: 5px;
}

.steps-content .ant-row {
  display: block !important;
}

.steps-content .ant-form-item-label {
  text-align: start !important;  
}

.steps-content {  
  margin-top: 16px;
  overflow: auto !important;  
} 

.steps-action {
  margin-top: 24px;
}

.grades-simulateResults td {
  padding: 4.5px !important;
  width: 480px !important;
}

.grades-simulateResultsTitle {
  padding-bottom: 14px;
  color: #fff;
  font-weight: 500;
  text-align: left;
  font-size: 0.85rem;
}

.opTableCreateBot .ant-table,
.opTableCreateBot .ant-table-thead > tr .ant-table-cell,
.opTableCreateBot td.ant-table-cell-fix-left,
.opTableCreateBot .ant-table-footer {
  background: #0E0E0E !important;
}
