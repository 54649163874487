/* main */

.mainLayout {
  min-height: 100vh;
}

.mainLogo {
  float: left;
}

.mainLogo img {
  max-width: 120px;
}

.mainMenu {
  background: #141414;
}

.contentLayout {
  background-color: #000 !important;
  padding: 0 10px 10px;
}

.mainContent {
  margin: 0;
  margin: 16px 0;
  max-width: 1600px;
  margin-top: 16px;
  background: #000;
}

.breadcrumb {
  margin: 16px 0 0;
}

.mainLoading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgb(31, 31, 31, 0.85);
}

.mainLoading .ant-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) ;
}