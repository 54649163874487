 .trades-container {
  padding: 9px;
  
}
.table-bots-container {
  width: 100%;
  max-width: 1600px;
}


@media only screen and (max-width: 1199px) {
  .table-bots-container {
    width: 1200px;
  }
  .tab-container .ant-tabs.ant-tabs-top.ant-tabs-mobile {
    overflow-y: hidden;
    overflow-x: auto !important;    
  }
}

.tab-container {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 9px;
  padding-bottom: 3px;
  background: #141414;
  overflow-y: hidden;
  overflow-x: auto !important;  
  
} 

.line {
  margin: 9px 0px;
}

.arrow-robo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .arrow-robo {
    animation: arrow-robo-float infinite 3s ease-in-out;
  }
}

@keyframes arrow-robo-float {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(9px);
  }
  100% {
    transform: translateX(0px);
  }
}

.table-btn {
  padding: 3px 3px;
}

.table-btn .ant-btn[disabled] {
  padding: 3px 3px;
}

.sell .ant-table {
  color: red;
  cursor: pointer;
}

.buy .ant-table {
  color: green;
  cursor: pointer;
}

.sell-trade .ant-table {
  color: red;  
}

.buy-trade .ant-table {
  color: green;  
}

.rowClass .ant-table-cell,
.sell-trade .ant-table-thead .ant-table-cell{
  padding-right: 10px !important;
}

.usrTableHistory .ant-table-container,
.usrTableHistory .ant-table-body {
  min-height: 342px !important;
  overflow: auto auto !important;   
}

.usrTableActive .ant-table-container,
.usrTableActive .ant-table-body {
  min-height: 375px !important;
  overflow: auto auto !important;   
}

.ant-table-tbody > tr > td {
  border-bottom: 0px;
  width: 13;
}

.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 0px;
}

.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 2px;
  font-size: 12px;
} 

.ant-table.ant-table-small .ant-table-tbody > tr > td:nth-child(1),
.ant-table.ant-table-small tfoot > tr > td:nth-child(1) {
  width: 120px ! important;
} 

/* td.ant-table-column-sort{ 
  background-color: #141414;
} */

/*
TODO: PEGAR WIDTH DO PARENT
*/
.slick-list {
  width: 875px;
}

.slick-slide {
  margin-right: 16px;
}

.tag-status-bot {
  width: 103px;
}

.ant-modal-header {
  background: #0e0e0e;  
}

.ant-modal-content {
  border: 2px solid #1f1f1f;
}

/* notify messages */

.notify .ant-notification-notice-message {
  font-weight: bold;
}

.notify .anticon.anticon-robot {
  color: #fff;
}

.notify--sell {
  background-color: red;
  font-weight: 500;
  min-width: 260px;
  width: 400px;
}

.notify--buy {
  background-color: rgb(2, 196, 2);
  font-weight: 500;
  min-width: 260px;
  width: 400px;
  /* background-color: #177ddc; */
}

.notify--cancel {
  background-color: orange;
  font-weight: 500;
  min-width: 260px;
  width: 400px;
}

.notify--newBot {
  background-color: #177ddc;
  font-weight: 500;
  min-width: 260px;
  width: 400px;
}

.notify--cancel,
.notify--cancel .anticon,
.notify--cancel .ant-notification-notice-message,
.notify--cancel .anticon.anticon-robot {
  color: #000;
}

.notify--newBot {
  background-color: #177ddc;
}
.usrTableActive .ant-table-row {
  transition: all 0.3s ease !important;
}

.usrTableActive .ant-table-row.highlight--BUY,
.usrTableActive .ant-table-row.highlight--BUY:hover {
  background-color: green !important;
}

.usrTableActive .ant-table-row.highlight--NEW_BOT,
.usrTableActive .ant-table-row.highlight--NEW_BOT:hover {
  background-color: #177ddc !important;
}

.usrTableActive .ant-table-row.highlight--EDIT_BOT,
.usrTableActive .ant-table-row.highlight--EDIT_BOT:hover {
  background-color: #177ddc !important;
}

.usrTableActive .ant-table-row.highlight--SELL,
.usrTableActive .ant-table-row.highlight--SELL:hover {
  background-color: red !important;
}

.usrTableActive .ant-table-row.highlight--SELL_MARKET,
.usrTableActive .ant-table-row.highlight--SELL_MARKET:hover {
  background-color: orange !important;
}

.usrTableHistory .ant-table-row .ant-table-cell:nth-child(7) span,
.usrTableActive .ant-table-row .ant-table-cell:nth-child(7) span {
  display: none;
}

.usrTableHistory .ant-table-row .ant-table-cell:nth-child(8) div,
.usrTableActive .ant-table-row .ant-table-cell:nth-child(8) div {
  position: relative;
}

.usrTableHistory .ant-table-row .ant-table-cell:nth-child(8) div span,
.usrTableActive .ant-table-row .ant-table-cell:nth-child(8) div span {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.usrTableActive .ant-table-row .ant-table-cell:nth-child(8) div.uow span {
  animation: zoom 2s infinite ;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  25% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  50% {
    opacity: 1;
    transform: scale(3);
  }
  75% {
    opacity: 0.5;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.screen-loader-container-trades {
  max-height: none !important;
  background: #000;
}

.screen-loader-container-trades-coins {
  max-height: none !important;
}