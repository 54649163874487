.plan-container {
  padding: 9px;
  max-height: 900px !important;
  max-width: 1300px;
  margin: 0 auto;     
  display: flex; 
}

.title-container {
  margin: 12px 12px 0 12px;
  /* background: #313131; */
  text-align: center;
  border-radius: 8px;
  font-size: 25px;
  padding: 10px;
}

.bots-container {
  background: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 10px;
  padding: 3px;
  margin-bottom: 0px !important;
}

/* .total-container {
  background-color: #181818;
  text-align: center;  
  font-size: 10px;
  font-weight: 500;
  padding: 7px;
  height: 50px;
} */

.ant-carousel .slick-dots li.slick-active > button {
  background: #FFF;
}

.ant-carousel .slick-dots > li > button {
  background: #FFF;
}

.card-detail .ant-card-body{
  padding: 0 !important;
}

.card-detail {
  background: #1D1C1C;
  border-radius: 8px;
  width: 320px;
}

.card-detail-main {
  background: #1D1C1C;
  border-radius: 8px;
  width: 420px;
}

.card-detail-main .ant-card-body {
  padding: 0;
}

.data-details {
  margin-left: 2px;
  background: #181818;
  height: 50px; 
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 316px;
}

.profit {
  margin-bottom: 25px;
  color: #19D242;
  font-weight: 500;
  font-size: 20px;
}

.value-plan {
  margin-bottom: 5px;
  font-size: 25px;
  color: #419FF5;
  font-weight: 500;
}
.carousel-plan .slick-list {
  width: 978px !important;
}

.content-style {
  height: 725px;
  color: #fff;
  width: 100%;
  line-height: 160px;
  text-align: center;
  display: flex;
};

.ant-carousel .slick-dots li  {
  height: 10px;
  border-radius:6px;
}

.ant-carousel .slick-dots li button {
  border-radius:6px;
  opacity: 0.4;
  height: 10px;
}

.ant-carousel .slick-dots li.slick-active button {
  border-radius:6px;
  background: #419FF6;
  height: 10px;
  
}
