/*Define uma borda para o header*/
.header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0px 24px;
  background: #141414;
  height: 60;
}

/*Alinha o botão do usuário*/
.ant-page-header-heading-extra {
  align-self: center;
}

/*Tira a borda do menu header*/
.ant-menu-horizontal {
  border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.header .ant-page-header-heading-left {  
  width: 100%;
}

.ant-menu-title-content .badge-exchange {
  display: flex;
  margin-left: -2px;    
}

.select-language .ant-col-6 {  
  display: flex;
  align-items: center;  
  height: 36px;
}

.message-without-exchange{
  background: #582a2a;
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: initial !important;
}

.screen-loader-container-main .ant-spin-dot-spin{
  display: none !important;
}

.screen-loader-container-main {
  top: 6% !important;
  max-height: none !important;
  background: #000;
}

.ant-spin-container::after {
  top: 6% !important;
}