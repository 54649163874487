.ant-modal-footer {
  text-align: center;
}

.contentStyle1 {
  width: 300px;
  margin-left: 7%;
  margin-top: 12%;
  text-align: center;
}

.contentStyle2 {
  width: 300px;
  margin-left: 5%;
  text-align: center;
}

.contentStyle3 {
  width: 300px;
  margin-left: 3%;
  margin-top: 12%;
  text-align: center;
}

.contentStyle4 {
  width: 300px;
  height: 100px;
  margin-left: 2%;
  margin-top: 10%;
  margin-bottom: -15%;
  text-align: center;
}